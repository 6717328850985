<template>
  <div class="page1">
    <EForm
      :formColumns="formColumns"
      :rowSize="4"
      :optionsBtn="true"
      :actionBtn="false"
      :formData="searchForm"
      ref="form"
      @onSearch="onSearch"
      :searchFlag="true"
      :exportShow="false"
    >
    </EForm>
<!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
<!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
    <ETable
      :tableTitle="tableTitle"
      :tableData="tableData"
      :needPagination="true"
      :count="count"
      @changePage="changePage"
      @changeSize="changeSize"
      :page="searchForm.current"
      :page_size="searchForm.size"
    >
      <div slot="signStatus" slot-scope="scope">
        {{scope.data.signStatus==1?'是':'否'}}
      </div>
    </ETable>
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import { identity_type, status } from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import {mapGetters} from 'vuex'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '姓名',
            prop: 'legalName',
          },
          {
            label: '手机号',
            prop: 'legalMobile',
          },
          {
            label: '提交时间',
            prop: 'createTime',
          },
          {
            label: '是否完成签约',
            prop: 'signStatus',
            type:'slot'
          },
        ],
        formColumns: [
          {
            title: '姓名',
            type: 'text',
            property: 'legalName',
            show: true,
          },

          {
            title: '手机号',
            type: 'text',
            property: 'legalMobile',
            show: true,
          },


          {
            title: '提交起始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true,
          },
          {
            title: '提交截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true,
          },
          {
            title: '是否签约',
            type: 'select',
            property: 'isPaySign',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '是',
                value: 1,
              },
              {
                label: '否',
                value: 0,
              },
            ],
          },

        ],
        tableData: [],
        searchForm: {
          legalName: null,
          legalMobile: null,
          createTimeStart: null,
          createTimeEnd: null,
          isPaySign: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();

    },
    components: { ETable, EDialog, EButton, EForm },
    computed:{
      ...mapGetters(['buttonList']),
    },
    beforeMount() {},
    methods: {
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },

      async getData() {
        let res = await Http.paySignApplyList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
.ws {
  width: 100%;
}
</style>
